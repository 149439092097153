
import {SETTINGS_TYPES} from "@/constant";
const SET_IS_SHOW_GENDER_COLLEAGE = 'SET_IS_SHOW_GENDER_COLLEAGE';
const SET_ITEM_SETTINGS = 'SET_ITEM_SETTINGS';
const SET_STATUS_SETTINGS = 'SET_STATUS_SETTINGS';

let storeData = localStorage.getItem(SETTINGS_TYPES.GENERAL);
const initialState1 = storeData ? JSON.parse(storeData) : {};
const initialState3 = {
    titleLoginPage: 'Учитесь по-другому',
    subTitleLoginPage: 'Авторизуйтесь, чтобы начать заниматься',
    imageLoginPage: null,
    status: 0
}
export const initialState = {
    ...initialState1,
    ...initialState3
}
console.log(initialState)
const settingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CHANGE_SETTINGS':
			return {
                ...state,
                ...action.payload
            };
        case SET_ITEM_SETTINGS: {
            return {
                ...state,
                [action.key]: action.value
            };
        }
        case SET_STATUS_SETTINGS: {
            return {
                ...state,
                status: action.status
            };
        }
		default:
			return state;
	}
};

export default settingsReducer;

export const changeSettings = (data) => {
    return {
        type: 'CHANGE_SETTINGS', 
        payload: data
    }
};

export const actionSetStatus = (status) => ({
    type: SET_STATUS_SETTINGS,
    status,
});

export const actionSetItemSetting = (key, value) => ({
    type: SET_ITEM_SETTINGS,
    key,
    value
});