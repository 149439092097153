import store from '@store/redux.store';
import { settingApi } from "@/api";
import {SETTINGS_TYPES} from "@/constant";
import { actionSetItemSetting, actionSetStatus, initialState } from '@store/settings-reducer';

export const checkSettingsStore = () => {
    return new Promise((resolve, reject) => {
        if (store.getState().settings.status) {
            resolve();
        } else {
            fethSettingsStore()
                .then(() => resolve())
                .catch((error) => reject(error));
        }
    })
}

const authPageSettingsKeys = [
    'titleLoginPage',
    'subTitleLoginPage',
    'imageLoginPage'
];

export const fethSettingsStore = () => {
    return new Promise((resolve, reject) => {
        store.dispatch(actionSetStatus(1));
        settingApi.getAll()
            .then(response => {
                const settings = response.data;
                if (!settings) {
                    store.dispatch(actionSetStatus(0));
                    reject(response);
                    return;
                }
                settings?.map(el => {
                    if ((el.type === SETTINGS_TYPES.TEACHER_ACCOUNT || el.type === SETTINGS_TYPES.CHILD_ACCOUNT) && el.key in initialState)
                        store.dispatch(actionSetItemSetting(el.key, el.value));
                    if (authPageSettingsKeys.includes(el.key))
                        store.dispatch(actionSetItemSetting(el.key, el.value));
                    if (el.key === 'authImage')
                        store.dispatch(actionSetItemSetting('imageLoginPage', process.env.REACT_APP_PATH_FILE + '/' + el.value));
                });
                store.dispatch(actionSetStatus(2));
                resolve();
            })
            .catch((error) => {
                store.dispatch(actionSetStatus(0));
                reject(error);
            })
    });
}