import s from './allHomeworkPage.module.css'

import { useState, useEffect, useMemo } from "react";
import { useSelector } from 'react-redux';
import {homeWorkApi} from '@/api'
import classNames from 'classnames'
import { useLoadMore } from '@/hooks/useLoadMore'
import {FrontTaskCardList} from '@common/FrontTaskCard/FrontTaskCardList'
import { selectorMyDirectionsSelect } from '@store/common/common.reducer';

import Button, { ButtonPrev } from "@UI/Button";
import { useInputNew } from '@UI/InputNew/useInputNew'
import Preloader from '@UI/Preloader'
import { InputSelect } from '@UI/InputNew'

import { rubberLayout } from '@/helpers/rubberLayout';

const AllHomeWorkPage = () => {
    useEffect(rubberLayout, []);

    const [type, setType] = useState(0)  
    const [direction, setDirection] = useState('');
    const filterHW = [`[status][eq]=${type}`]
    if (direction) filterHW.push(`[direction_id][eq]=${direction}`);

    const { state: res, getState, setState, ref, loading, loadingAll } = useLoadMore({
      api: homeWorkApi.getAllWorks,
      params: { filter: filterHW, expand: ['lesson.lessonTasks', 'lesson.topic', 'direction', 'homeWorkResults', 'lesson.lessonTasks.task', 'homeWorkUniqueTasks.task'] },
      mode: 'lazyLoading',
      initState: [],
      last: false,
    })
  
    const state = useMemo(() => res?.map(item => item.is_unique === 1 ? { ...item, lesson: { lessonTasks: item?.homeWorkUniqueTasks, name: 'Уникальное' } } : item), [res])
    useEffect(() => { getState()}, [type, direction])

    const myDirections = useSelector(selectorMyDirectionsSelect).filter((item, index, self) => index === self.findIndex(t => t.value === item.value))
    console.log(myDirections)

    const [bindDirection] = useInputNew({
      name: 'direction',
      value: direction,
      onChange: (e) => setDirection(e),
      options: myDirections ?? [],
      placeholder: 'Направление'
    })
  return <>
    
    <main className={classNames('page-content', 'pagePadding', s.page)}>
      <div style={{marginBottom: '20px', flexWrap:'wrap'}} className="newDesignHeader">
        <ButtonPrev noHistory={true} newDesign />
        <div className="newDesignTitleText">Мои задания</div>
      </div> 
       
       <div className={s.filter}>
        <Button newDesign newDesignYellow={type === 0} action={() => setType(0)}>Не выполнено</Button>
        <Button newDesign newDesignYellow={type === 5} action={() => setType(5)}>Выполнено</Button>
        <Button newDesign newDesignYellow={type === 10} action={() => setType(10)}>Проверенно</Button>
        <Button newDesign newDesignYellow={type === 15} action={() => setType(15)}>Архив</Button>
        <InputSelect newDesign {...bindDirection} />
      </div>
     
  
      {loadingAll
        ? <Preloader fullScreen='center' />
        : <FrontTaskCardList loading={loading} refD={ref} works={state} setState={setState} />
      }

    </main>
  </>
}

export default AllHomeWorkPage;