import s from './MainChatsView.module.css';
import {useInput} from "@/hooks";
import Search from "@UI/Search";
import Button, {CollapseBtn} from '@UI/Button'
import {useEffect, useRef, useState} from "react";
import {AddNewChatIcon, CloseIcon, CompressIcon} from "@/utils/icons";
import {chatApi} from "@/api";
import ChatViewList from "@common/Chat/components/ChatViewList";
import {useClickAway} from "react-use";
import UserList from "@common/Chat/components/UserList";
import {useSelector} from "react-redux";
import {selectProfile} from "@store/profile.reducer";
import {SettingsService} from "@/services/SettingsService";
import {SETTINGS_TYPES} from "@/constant";
import rolesEnum from "@/constant/rolesEnum";

const MainChatPage = ({setPage, activeUsers, unReadChats}) => {
    const [bindSearch, valueSearch, reset] = useInput('')
    const [users, setUsers] = useState([]);
    const [isShowUsers, setIsShowUsers] = useState(false);
    const [chats, setChats] = useState([]);
    const userRef = useRef(null);
    const [isNeedUpdate, setIsNeedUpdate] = useState(false);
    const [state, setState] = useState({
        search: null,
    });
    const [isShowDM, setIsShowDM] = useState(null);
    const [sortedChats, setSortedChats] = useState([]);
    const profile = useSelector(selectProfile);

    useEffect(() => {
        if(isShowDM === null){
            setSortedChats(chats)
            return;
        }
        setSortedChats(chats.filter(element => 
            isShowDM ? element?.name === null : element?.name !== null
        ));
    }, [isShowDM, chats]);

    const canStartChat = () => {
        const childrenCanStartChat = SettingsService.get(SETTINGS_TYPES.GENERAL)?.isChildrenCanStartChat === '1'

        return profile.role !== rolesEnum.children || childrenCanStartChat
    }

    useClickAway(userRef, () => {
        setIsShowUsers(false);
    });

    useEffect(() => {
        chatApi.getChatList({
            limit: 10000,
        })
            .then((res) => {
                if (res?.data) {
                    let result = res?.data.sort((a,b) =>  (a.isFixed === true && !b.isFixed) ? -1 : ((b.isFixed === true && !a.isFixed) ? 1 : 0));
                    setChats(result)

                }
            }).then(() => setIsNeedUpdate(false))
    }, [isNeedUpdate])
    const getUsers = () => {
        chatApi.getUsersChatsList({
            search: valueSearch,
        })
            .then(response => {
                if (response?.data) {
                    setUsers(response?.data.slice(0,20))
                }
            })
        ;
    }

    useEffect(() => {
        getUsers()
    }, [state]);

    const dataSearch = {
        bind: bindSearch,
        onChange: () => {
            setState({
                search: valueSearch,
            })
        }
    }

    const handleCloseUsersList = () => {
        setIsShowUsers(false)
        reset()
    }

    const handleCollapseBtnClick = () => setPage('default')

    return (
        <div className={s.page}>
            <div className={s.tabs}>
                <Button action={()=>setIsShowDM(null)} className={isShowDM === null && s.tabActive} newDesign>
                    Все
                </Button>
                <Button action={()=>setIsShowDM(true)} className={isShowDM === true && s.tabActive} newDesign>
                    Личные
                </Button>
                <Button action={()=>setIsShowDM(false)} className={isShowDM === false && s.tabActive} newDesign>
                    Группы
                </Button>
            </div>
            <div className={s.header}>
                {canStartChat() && <>
                    <Search
                      placeholder="Введите ФИО собеседника"
                      className={s.search} {...dataSearch}
                      withClose={false}
                      onFocus={() => setIsShowUsers(true)}
                    />
                    {isShowUsers && <button className={s.close} onClick={handleCloseUsersList}><CloseIcon/></button>}
                    <button className={s.addBtn} onClick={() => setPage('create')}>
                        <AddNewChatIcon width={'36'} height={'36'}/>
                    </button>
                    <span className={s.desc}>Cоздать чат</span>
                    <CollapseBtn onClick={handleCollapseBtnClick} color="red" style={{ marginLeft: '4px'}} />
                </>}
            </div>
            {isShowUsers && <UserList users={users} setPage={setPage} activeUsers={activeUsers}/>}
            {!isShowUsers && <ChatViewList chats={sortedChats} setPage={setPage} setIsNeedUpdate={setIsNeedUpdate} unReadChats={unReadChats}/>}
        </div>

    )
}

export default MainChatPage;