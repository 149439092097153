import React, {useEffect, useState} from "react";
import s from "./index.module.css";
import {BalanceIcon, GetIco, SupportIcon} from "@/utils/icons";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import {connect, useDispatch, useSelector} from "react-redux";
import { ButtonEditNew } from "@UI/Button";
import { selectProfile } from "@store/profile.reducer";
import { formateName } from "@helpers/formateName";
import { getAvatar } from "@helpers/getAvatar";
import { selectorOwnBacklogCount } from "@store/children/children.reducer";
import { selectorTotalCountUHW } from "@store/seminarian/seminarian.reducer";
import {convertNumberCost} from "@/helpers/convertNumberCost";
import setSettingsFromServer from "@/helpers/setSettingsFromServer"
import { fetchMenu } from "@store/menu.reducer";
import classNames from "classnames";
import BalanceBlock from "@UI/BalanceBlock";
import QModals from "@pages/Main/Requests/Qmodals";

const Sidebar = () => {
  const profile = useSelector(selectProfile);
  const isShow = useSelector((state) => state.page.showSidebar);
  const [logo, setLogo] = useState('/logo.svg')
  const [show, setShow] = useState(false)
  const [type, setType] = useState(false)
  const handleClick = () => {
    setShow(true)
    setType('ProblemSite')
  }
  const balance = profile?.balance !== undefined ? profile.balance : 0;
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    setSettingsFromServer(setSettings)
  }, []);

  const isDisableStudentSupportRequests = settings?.isDisableStudentSupportRequests == '1';
  const isDisableEmployeeSupportRequests = settings?.isDisableEmployeeSupportRequests == '1';

  useEffect(() => {
    if (settings?.mainLogo) {
      setLogo(process.env.REACT_APP_PATH_FILE + (settings?.mainLogo))
    }
  }, [settings])

  return (
    <>
      <aside className={cn({ [s.show]: isShow }, s.wrapper, "app-sidebar")}>
        <div className={s.userInfo}>
          <div className={s.logoWrapper}>
            <img
                className={s.avatar}
                src={getAvatar(profile)}
                alt={profile?.name}
              />
          </div>
          <div className={s.userData}>
            <div className={s.fio}>
              {formateName(profile?.name, "F I")}
              {showUserCustomId(profile)}
            </div>
            <div className={s.mail}>
              {profile?.email}
            </div>
            <Link to="/personal" className={s.linkEdit}>
               <ButtonEditNew className={s.btnEdit} />
             </Link>
          </div>
        </div>

        <NavigationApp />

        <div className={s.sidebarFooter}>
          {profile?.role === "children" && settings?.isFreeCourses === '0' && (
              <BalanceBlock profile={profile}/>
          )}

          {
            ((profile?.role === "children" && !isDisableStudentSupportRequests) || (profile?.role !== "children" && !isDisableEmployeeSupportRequests)) && <div className={classNames(s.support, s.navLink)} onClick={handleClick}>
              <SupportIcon />
              Поддержка
            </div>
          }
          
        </div>
      </aside>
      <QModals type={type} active={show} setActive={setShow} />
    </>
  );
};

export default Sidebar

const MenuItem = ({ onClose, ...props }) => {
  const item = props.item;
  const title = props.item?.title;

  if (item.url.startsWith("http"))
    return (
      <a
        href={item.url}
        onClick={onClose}
        className={s.navLink}
        title={title}
        target="_blank"
        rel="noreferrer"
      >
        <GetIco icon={item.icon} className={s.navIco} />
        {title}
      </a>
    );

  return (
    <NavLink
      exact={item.url === "/" && true}
      to={item.url}
      onClick={onClose}
      className={s.navLink}
      activeClassName={s.active}
      title={title}
    >
      <GetIco icon={item.icon} className={s.navIco} /> {title}
      {/*{item.has_notify && <Notify url={item.url} />}*/}
      {item?.noticeCount > 0 && <span className={cn("notice", s.notice)}>{item.noticeCount}</span>}
    </NavLink>
  );
};

export const NavigationApp = connect(
    state => ({ menu: state.menu.items }),
    { fetchMenu}
)(({ menu, fetchMenu, onClose }) => {
  useEffect(_ => {
    fetchMenu()
  }, []);

  return (
    <nav className={cn(s.navList, "list-reset")}>
      {menu && menu?.map((item) => (
        <MenuItem key={item.id} item={item} onClose={onClose} />
      ))}
    </nav>
  );
});

const Notify = ({ url }) => {
  let count = 0;
  const unverifiedHWCount = useSelector(selectorTotalCountUHW);
  if (url === "/dz-students" && unverifiedHWCount > 0)
    count = unverifiedHWCount;

  const ownBacklogCount = useSelector(selectorOwnBacklogCount);
  if (url === "/home-work" && ownBacklogCount > 0) count += ownBacklogCount;

  if (count === 0) return <></>;
  return <span className={cn("notice", s.notice)}>{count}</span>;
};

function showUserCustomId(profile) {
  let customId = profile?.custom_id

  return customId && (
    <span className={s.customId}>
      {customId}
    </span>
  );
}