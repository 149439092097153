import s from "@common/Chat/MainChatPage/MainChatsView.module.css";
import ChatView from "@common/Chat/components/ChatView/ChatView";
import Popup from "@common/Chat/components/Popup/Popup";
import {useEffect, useRef, useState} from "react";
import {chatApi} from "@/api";
import {useAddAlert} from "@/hooks/useAddAlert";
import classNames from "classnames";

const ChatViewList = ({chats, setPage, setIsNeedUpdate, unReadChats}) => {
    const addAlert = useAddAlert();
    const [isVisiblePopup, setIsVisiblePopup] = useState(false);
    const [type, setType] = useState(null);
    const [chatId, setChatId] = useState(null);
    const [action,setAction] = useState(null)
    const [header, setHeader] = useState(null);
    const [actionCallback, setActionCallback] = useState(null)
    useEffect(() => {
        if (action) {
            if (type === 'remove') {
                deleteChat(chatId)
            }else if (type === 'unfix') {
                unFixChat()
            } else {
                fixChat(chatId)
            }
            setAction(false)
            setIsVisiblePopup(false)
            setType('')
        }

    }, [type, action]);

    const unFixChat = () => {
        chatApi.unFixChat().then(res => {
            if (res?.status === 200) {
                setIsNeedUpdate(true)
            } else {
                addAlert({text: res?.response?.data?.error})
            }
        })
    }

    const deleteChat = (id) => {
        chatApi.removeChat(id).then((res) =>  {
            if (res?.status === 200) {
                setIsNeedUpdate(true)
            } else {
                addAlert({text: res?.response?.data?.error})

            }
        })
    }

    const fixChat = (id) => {
        chatApi.fixChat(id).then((res) =>  {

            if (res?.status === 200) {
                setIsNeedUpdate(true)
            }
        })
    }

    return (
        <>
            <div className={classNames('new-design-scroll', s.chats, s.order)}>
                {chats && chats.map(chat => (
                    <ChatView
                        setAction={setAction}
                        chat={chat}
                        key={chat.id}
                        setHeader={setHeader}
                        setPage={setPage}
                        unReadChats={unReadChats}
                        isVisiblePopup={isVisiblePopup}
                        setChatId={setChatId}
                        setActionCallback={setActionCallback}
                        setType={setType}
                        action={action}
                        setIsVisiblePopup={setIsVisiblePopup} closeCallback={() => setIsVisiblePopup(false)}
                    />
                ))}
            </div>
            {isVisiblePopup && <Popup
                isVisiblePopup={isVisiblePopup}
                closeCallback={() => setIsVisiblePopup(false)}
                header={header}
                action={action}
                setAction={setAction}
                setActionCallback={setActionCallback}
            />}
        </>

    )
}

export default ChatViewList;