import classNames from "classnames";
import colors from "./colors.module.css";
import s from "./index.module.css";

const SelectColor = ({ onClick, value, className, type }) => {
  const colorList = ["accent", "accentFirst", "blueGray", "gray", "white", 'violet', 'violetDark', 'blueDeep', 'yellowDeep', 'pinkDeep', 'greenDeep']
  const colorListCode = ['#6e368c', '#ed6b69', '#e6ecf2', '#a3a3a5', '#ffffff', '#ae9be4', '#9a82dd', '#0cc1f3', '#feca06', '#ee347d', '#4bb042']
  return <ul className={classNames(s.colorList, { [className]: className })}>
    {colorList.map((el, i) => <li key={el} onClick={() => type === 'code' ? onClick(colorListCode[i]) : onClick(el)} className={classNames(s.colorItem, colors[el], { [s.active]: el === value })}></li>)}
  </ul>
}

export default SelectColor
