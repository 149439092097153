import s from './ChatMessage.module.css'
import { ClipIcon, DotsIcon} from "@/utils/icons";
import Moment from 'react-moment';
import moment from "moment";
import 'moment/locale/ru';
import UserImage from "@common/Chat/components/UserImage/UserImage";
import React, {useRef, useState} from "react";
import classNames from "classnames";
import {chatApi} from "@/api";
import {useSelector} from "react-redux";
import {selectProfile} from "@store/profile.reducer";
import {isJson} from '@/helpers/isJson';
import {containsHtmlTags} from '@/utils/RegexUtils/regexMatch';

moment.locale('ru')
const FixedMessage = ({message, data, customClass, setIsNeedUpdate, scrollToClip}) => {
    const [isActive, setIsActive] = useState(false);
    const profile = useSelector(selectProfile);
    const myRole = data?.members?.find(el => el.user_id === profile.id).role;
    const openMenu = (e) => {
        e.stopPropagation()
        setIsActive(!isActive)
    }

    const menuRef = useRef(null)
    moment.locale('ru' )
    const authorName = data?.members?.find(member => message.author === member.member_id)

    const handleUnfix = (e) => {
        e.stopPropagation()
        setIsActive(false)
        chatApi.unFixMessage(data.id).then((res) => setIsNeedUpdate(true))
    }

    return (
        <div className={classNames(customClass, s.message)} onClick={scrollToClip}>
            <div className={s.clipMessageWr}>
                <div className={s.clip}>
                    <ClipIcon width={'24'} height={'24'}/>
                </div>
                <div className={s.photo}>
                    <UserImage path={authorName.avatar}/>
                </div>
                <div className={s.chatBody}>
                    <div className={s.chatHead}>
                        <div className={s.authorName}>
                            {authorName.name}
                        </div>
                        <div className={s.date}>
                            <Moment locale="ru" date={message?.created_at} format="D MMM YYYY" withTitle/>
                        </div>
                    </div>
                    { 
                        (message.message && containsHtmlTags(message.message)) ? 
                        (<div dangerouslySetInnerHTML={{ __html: message.message }} className={classNames(s.clipMessageText, s.messageDz)} />) :
                        (<div className={s.clipMessageText}>{isJson(message.message) ? JSON.parse(message.message) : message.message}</div>)
                    }
                </div>
                {myRole === 1 && <>
                    <div className={s.dots} onClick={(e) => openMenu(e)}><DotsIcon/></div>
                    <div className={classNames(s.menu, isActive ? s.active : '')} ref={menuRef}
                         onMouseLeave={() => setIsActive(false)}>
                        <div className={s.menuRow} onClick={handleUnfix}>
                            <div className={s.menuIcon}><ClipIcon width={'16'} height={'16'}/></div>
                            <div className={s.menuName}>Открепить сообщение</div>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    )
}
export default FixedMessage;