import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  directionAPI,
  directionGroupApi,
  directionOrdersApi,
  homeWorkApi,
  usersAPI,
} from "@api";
import { createDate } from "@helpers/date";
import { useCalendar } from "@hooks";
import { actionGetOwnBacklog } from "@store/children/children.reducer";
import {
  getMyTimetableState,
  setMyClassesState,
  setMyDirectionState,
  setMyGroupsState,
} from "@store/common/common.reducer";
import { setUnverifiedHM } from "@store/seminarian/seminarian.reducer";
import { selectProfile, actionUpdProfile } from "@store/profile.reducer";
import { AuthService } from "@/services/AuthService";
import { useHistory } from "react-router-dom";
import { selectIsAuth } from "@store/profile.reducer";
import { SETTINGS_TYPES } from "@/constant";

const Technical = () => {
  var d = useDispatch();
  var profile = useSelector(selectProfile);
  const stateAll = useSelector((state)=>state);
  
  var newDate = useMemo(() => new Date(), []);
  var { state: month } = useCalendar({
    selectedDate: newDate,
    firstWeekDay: 2,
  });

  var isAuth = useSelector(selectIsAuth);
  var locationIsLogin =
    window.location.pathname === "/auth" ||
    window.location.pathname === "/register" ||
    window.location.pathname === "/restore-password" ||
    window.location.pathname === "/signup-vk" ||
    window.location.pathname === "/auth/app/" ||
    window.location.pathname === "/auth" ||
    window.location.pathname.match(/news\/[0-9]/)
      ? true
      : false;

  const technical = async () => {
    var date = createDate();

    d(getMyTimetableState({ year: date.year, month: date.monthNumber }, month));
    // Все кроме УЧЕНИКОВ
    if (profile.role ) {
      const res = await usersAPI.getUser({ id: profile.id, expand: ["roles", "userProfile"] });
      if (res?.data) {
        const data = { ...res.data };
        if (res?.data?.roles.length > 0)
          data.roles = res?.data?.roles?.map((i) => i?.item_name);
        d(actionUpdProfile(data));
      }
    }

    switch (profile?.role) {
      case "children": {
        {
          // Получаем направления ребенка
          const res = await directionOrdersApi.get({
            filter: [`[children_id][eq]=${profile.id}`, `[is_disabled][eq]=0`],
            expand: ["direction.directionTariffs", "group", "class"],
            pageSize: false,
          });
          d(
            setMyDirectionState(
              res?.data?.data.map(({ direction, ...order }) => ({
                ...direction,
                order,
              }))
            )
          );

          const classesIds = []
          
          d(
            setMyClassesState(
              res?.data?.data.filter((item) => { 
                  if(item?.class?.id && !classesIds.includes(item?.class?.id)){
                    classesIds.push(item?.class?.id)
                    return true
                  }
                  return false
              }).map(( el ) => el?.class)
            )
          );

          const groupsMy = res.data.data
            .map((item) => item.group)
            ?.filter((item) => item?.id !== undefined);
          d(setMyGroupsState(groupsMy));
        }

        // Получаем не выполненное дз, пробники, кр и соп
        d(actionGetOwnBacklog());

        break;
      }
      case "senior-seminarian": {
        d(actionUpdProfile({ directionIds: profile.directionIdsSS }));
        {
          const res = await directionAPI.getAll({
            filter: [`[direction_id][in][]=${profile.directionIdsSS}`],
            expand: ['directionTariffs']
          });
          d(setMyDirectionState(res?.data?.data));
        }

        {
          // получение своих групп
          const res = await directionGroupApi.get({
            filter: profile.directionIdsSS?.map(
              (item) => `[direction_id][in][]=${item}`
            ),
            expand: ["direction"],
          });
          d(setMyGroupsState(res.data));
        }

        break;
      }
      case "seminarian": {
        {
          
          // получение своих групп
          const res = await directionGroupApi.get({
            filter: [`[teacher_id][eq]=${profile.id}`],
          });
          const data = { groupsIds: [] };
          if (res.data.length)
            res.data.forEach((item) => data.groupsIds.push(item.id));
          d(actionUpdProfile(data));
          d(setMyGroupsState(res.data));
        }

        {
          // получение направлений
          const res = await usersAPI.directionBySeminarian({
            id: profile?.id,
            params: { expand: "direction.directionTariffs" },
          });
          
          const directions = res?.data?.data?.map(({ direction }) => direction);
          d(
            actionUpdProfile({
              directionIds:
                profile?.directionIds ?? directions?.map(({ id }) => id),
              myDirection: directions,
            })
          );
          d(setMyDirectionState(directions));
        }

        {
          // получение не проверенных домашек
          const res = await homeWorkApi.getOwnWorks({
            filter: [`[status][eq]=5`],
            expand: ["direction", "children"],
            pageSize: false,
          });
          d(
            setUnverifiedHM({
              list: res.data?.data,
              count: res.data?._meta?.totalCount,
            })
          );
        }
        break;
      }
      default:
        break;
    }
  };

  const history = useHistory();

  const logout = async () => {
    await AuthService.logout();
    history.push("/auth");
  };

  useEffect(() => {
    if (isAuth) technical();
    else if (!locationIsLogin && !isAuth) logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.role]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem(SETTINGS_TYPES.GENERAL);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return null;
};

export default Technical;
