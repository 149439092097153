import {menuApi} from "@/api";

const SET_MENU = "impulse/menu/SET_MENU";
const ADD_ITEM_MENU = "impulse/menu/ADD_ITEM_MENU";
const DELETE_ITEM_MENU = "impulse/menu/DELETE_ITEM_MENU";
const UPDATE_ITEM_MENU_URL = "impulse/menu/UPDATE_ITEM_MENU_URL";

const initialState = {
  items: [],
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU: {
      return {
        items: action.menu
      };
    }
    case ADD_ITEM_MENU: {
      return {
        items: [
          ...state.items,
          action.newItem
        ]
      };
    }
    case UPDATE_ITEM_MENU_URL:
      return {
        items: state.items.map((item) =>
          item.id == action.id ? { ...item, url: action.url } : item
        ),
      }
    case DELETE_ITEM_MENU:
      return {
        items: state.items.filter((item) => item.id != action.id)
      }
    default:
      return state;
  }
};

export const actionSetMenu = (menu) => ({
  type: SET_MENU,
  menu,
});

export const actionAddItemMenu = (newItem) => ({
  type: ADD_ITEM_MENU,
  newItem,
});

export const actionUpdateMenuItemUrl = (id, url) => ({
  type: UPDATE_ITEM_MENU_URL,
  id,
  url
});

export const actionDeleteMenuItem = (id) => ({
  type: DELETE_ITEM_MENU,
  id
});

export const fetchMenu = () => (dispatch) => {
  
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  
  menuApi
    .getMenu(selectedLanguage, 'basic')
    .then(({data}) => dispatch(actionSetMenu(data)))
};

export default menuReducer;
