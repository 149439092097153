import classNames from "classnames";
import { createDate } from "@/helpers/date";
import { declOfNum } from "@/helpers/declOfNum";
import { formateName } from "@/helpers/formateName";
import { geStatRowList } from "@/helpers/geStatRowList";
import { getAvatar } from "@/helpers/getAvatar";
import { InputTextarea } from "@UI/InputNew";
import Button from "@/components/UI/Button";
import StatRow from "@/components/UI/StatRow";
import s from "./FrontTaskCard.module.css";
import Preloader from "@/components/UI/Preloader";
import {useState, useEffect} from "react";
import { useAddAlert } from "@/hooks/useAddAlert";
import { homeWorkApi, probeApi, controlApi } from "@/api";
import { Link } from "react-router-dom";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import moment from 'moment';
import 'moment/locale/ru';

const formatDate = (d) => {
  if( typeof d === 'string' && !d.includes('-')){
    d=Number(d);
  }
  const date = createDate({
    date: new Date(typeof d === "number" ? d * 1000 : d),
  });
  if (d) return ` ${date.dayNumber} ${date.monthGenitive}`;
  return "";
};

function formatDateToDate(d) {
  const date = createDate({
    date: new Date(typeof d === "number" ? d * 1000 : d),
  });
  if (d) return ` ${date.dayNumber}.${date.monthNumber}.${date.year}`;
  return "";
}

const FrontTaskCard = ({ data, role = "children", ...props }) => {
  
  const isMobile = useScreenWidth(767);

  if (!data) return <div className={s.wrapper}>Нет данных</div>;

  const markText = `Оценка: ${(data?.mark=='1001' ? 'СМ' : data?.mark=='1002' ? 'Зачет' : data?.mark=='1003' ? 'Незачет' : data?.mark)?? "нет"}`;
  
  let text = "Перейти к ";
  switch (data.type) {
    case "probe":
      text += "пробнику";
      break;
    case "control":
      text += "контрольной";
      break;
    case "sop":
      text += "СОП";
      break;
    default:
      text += "дз";
  }

  return (
    <div className={s.wrapper}>
      {
        !isMobile && (<>
          <CardInformation {...{data, markText, role}} />
          <div className={s.verticalDivider}/>
      </>)
    }
      <div className={s.rightSideCard}>
        {role === "children" ? (
          <HeaderStudent {...data} />
        ) : (
          <HeaderTeacher {...data} />
        )}
        <Head {...data} />
        {data.type === "abstract" && <Abstract {...data} />}
        {data?.lesson?.lessonTasks && <TaskList role={role} {...data} />}
        {isMobile && <CardInformation {...{data, markText, role}} />}
        <Action {...{ data, role, ...props }} />
      </div>
    </div>
  );
};

export default FrontTaskCard;

const HeaderStudent = (props) => {
  const getTitle = () => {
    if(props?.is_classwork=='1' && props?.type == "unique"){
      return "Уникальная классная работа";
    }
    if(props?.is_classwork=='1'){
      return "Классная работа";
    }

    switch (props?.type) {
      case "probe":
        return "Пробник";
      case "control":
        return "Контрольная работа";
      case "unique":
        return "Уникальная домашняя работа";
      case "abstract":
        return "Конспект";
      default:
        return "Домашняя работа";
    }
  };

  // mark in checked homework
  // const getMark = () => {
  //   let mark = 1;
  //   if (props.decided_right === 0 || props.decided_right === null) {
  //     return mark;
  //   }
  //   if (props.decided_right >= 20) {
  //     mark = 2;
  //     if (props.decided_right >= 40) {
  //       mark = 3;
  //       if (props.decided_right >= 60) {
  //         mark = 4;
  //         if (props.decided_right >= 80) {
  //           mark = 5;
  //         }
  //       }
  //     }
  //   }

  //   return mark;
  // };

  return (
    <div className={s.headStudent}>
      <h2 className={s.title}>{getTitle()}</h2>

      <div className={classNames(s.headerInformationBlock)}>
        <span className={classNames(s.headerInformationBlockText)}>
          {props.decided_right ?? 0}% / 100%
        </span>
        <span className={classNames(s.headerInformationBlockText)}>
          Кол-во попыток: {props?.attempt ?? 0}
        </span>
        {/* <span className={classNames(s.headerInformationBlockText)}>
          Время выполнения: 15 минут
        </span> */}
      </div>
    </div>
  );
};

const HeaderTeacher = (props) => {
  return (
    <div className={s.headerTeacher}>
      <div className={s.nameWrap}>
        <div className={classNames("ava", s.ava)}>
          <img src={getAvatar(props.children)} alt="" />
        </div>
        <h2 className={s.title}>
          {formateName(props?.children?.name, "F I")}{" "} {props?.children?.custom_id ? `ID: ${props?.children?.custom_id}` : '' }
          {process.env.REACT_APP_DEV_MODE === "1" && (
            <span className="error">{props?.children?.id}</span>
          )}
        </h2>
      </div>

      <div className={classNames(s.headerInformationBlock)}>
        <span className={classNames(s.headerInformationBlockText)}>
          {props.decided_right ?? 0}% / 100%
        </span>
        <span className={classNames(s.headerInformationBlockText)}>
          Кол-во попыток: {props?.attempt ?? 0}
        </span>
        {/* <span className={classNames(s.headerInformationBlockText)}>
          Время выполнения: 15 минут
        </span> */}
      </div>
    </div>
  );
};

const Head = (props) => {
  return (
    <div className={s.head}>
      {props?.direction && (
        <ValueBox title="Направление" text={props.direction.name} gravityRight/>
      )}
      {props?.lesson?.topic && (
        <ValueBox title="Тема" text={props.lesson.topic.name} gravityRight/>
      )}
      {props?.lesson && <ValueBox title="Урок" text={props.lesson.name} gravityRight/>}
    </div>
  );
};

const TaskList = (props) => (
  <div className={s.taskList}>
    <StatRow role={props.role} values={geStatRowList(props)} type={"one"} />
  </div>
);

const Abstract = (props) => (
  <div className={s.abstract}>
    {props?.files?.map((file, index) => (
      <a
        key={file.id}
        href={process.env.REACT_APP_PATH_FILE + file.file_path}
        target="_blank"
        rel="noreferrer"
      >
        <button className="btn">Открыть файл №{index + 1}</button>
      </a>
    ))}

    <InputTextarea
      className={s.textarea}
      {...{
        value: props.comment ?? "Учитель не дал комментарий",
        disabled: true,
        title: "Комментарий учителя",
      }}
    />
  </div>
);

const Action = ({ data, ...props }) => {

  let text = "Перейти к ";
  let textDelete = "Удалить ";
  let removeApi = props?.remove ? homeWorkApi.remove : null;
  let textSuccess = '';

  switch (data.type) {
    case "probe":
      text += "пробнику";
      textDelete += "пробник";
      textSuccess = 'Пробник удален';
      removeApi = probeApi.remove;
      break;
    case "control":
      text += "контрольной";
      textDelete += "контрольную";
      removeApi = controlApi.remove;
      textSuccess = 'Контрольная удалена';
      break;
    case "sop":
      text += "СОП";
      textDelete += "СОП";
      break;
    default:
      text += "дз";
      textDelete += "дз";
      textSuccess = 'Домашнее задание удалено';
  }

  const link = props.link ? props.link : `/home-work/work-${data.id}`;

  const [loadingDel, setLoadingDel] = useState(false);
  const addAlert = useAddAlert();

  const remove = (id) => {
    setLoadingDel(true);
    removeApi({ id }).then((res) => {
      setLoadingDel(false);

      if (res?.data?.status === "success") {
        props.setState((prev) => prev?.filter((item) => item.id !== id));
        addAlert({ title: textSuccess });
      } else {
        addAlert({ title: 'Ошибка', text: 'Упс! Ошибка. Попробуйте ещё раз или обратитесь к администратору', type: 'error' })
      }
    });
  };

  const [loadingArchive, setLoadingArchive] = useState(false);

  const archive = (id, type) => {

    setLoadingArchive(true);
    homeWorkApi.archive(id, type).then((res) => {
      setLoadingArchive(false);
      if (res?.data?.status === "success") {
        addAlert({ title: "Домашнее задание отправленно в архив" });
        props.setState((prev) => prev?.filter((item) => item.id !== id));
      } else {
        addAlert({ title: 'Ошибка', text: 'Упс! Ошибка. Попробуйте ещё раз или обратитесь к администратору', type: 'error' })
      }
    });
  }

  const [loadingUnarchive, setLoadingUnarchive] = useState(false);

  const unarchive = (id, type) => {
    setLoadingUnarchive(true);
    homeWorkApi.unarchive(id, type).then((res) => {
      setLoadingUnarchive(false);
      if (res?.data?.status === "success") {
        addAlert({ title: "Домашнее задание разархивировано" });
        props.setState((prev) => prev?.filter((item) => item.id !== id));
      } else {
        addAlert({ title: 'Ошибка', text: 'Упс! Ошибка. Попробуйте ещё раз или обратитесь к администратору', type: 'error' })
      }
    });
  }

  // const handleClick = () => {
  //   history.push('/about'); // Перемещение на страницу "/about"
  // };
  
  return (
    <div className={s.action}>
      {data.type !== "abstract" && (data?.status != 15 || props.role != "children") && <>

      <Btn link={data?.deny_access ? '/payment' : link} text={text} />
        
          {/* <Btn link={data?.deny_access ? '/payment' : link} text={text} /> */}
          </>
      }
      {data?.lesson?.redo_homework === 1 &&
        props.role === "children" &&
        data?.status !== 10 &&
        data?.status !== 0 && (
          <Btn link={`${link}?redo=ok`} text={"Перерешать дз"} />
        )}
      <div className={s.rightBtn}>
        {data?.status === 5 &&
          props.role === "teacher" &&
          data?.attempt > 1 && (
            <span className={classNames("booble booble--blue", s.actionBooble)}>
              Перерешено: {+data?.attempt - 1}
            </span>
          )}
        {data.status < 5 && JSON.parse(localStorage.getItem('profile')).role!='children' && (
            loadingArchive ? <Preloader /> : <Button action={() => archive(data.id, data.type)} className={classNames(s.buttonActionRounded)} newDesign>
              Отправить в архив
            </Button>
          )
        }
        {data.status==15 && JSON.parse(localStorage.getItem('profile')).role!='children' && (
            loadingUnarchive ? <Preloader /> : <Button action={() => unarchive(data.id, data.type)} className={classNames(s.buttonActionRounded)} newDesign>
              Разархивировать 
            </Button>
          )
        }
        {props?.remove &&
          data.status < 6 &&
          (loadingDel ? (
            <Preloader />
          ) : (
            <Button action={() => remove(data.id)} className={classNames(s.buttonActionRounded, s.buttonActionAlert)} newDesign>
              { textDelete }
            </Button>
          ))}
      </div>
    </div>
  );
};

const ValueBox = ({ title, text, gravityRight }) => (
  <div className={s.valueBox}>
    <span className={s.valueBoxTitle}>{title}</span>
    <span className={classNames("booble booble--blue", s.actionBooble, {[s.gravityRight]: gravityRight,} )}>{text}</span>
  </div>
);
const CardInformation = ({data, markText, role}) => {
  console.log(data)
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);
  const [isLateSubmission, setIsLateSubmission] = useState(false);

  useEffect(() => {
    try {
      if (data?.deadline_date) {
        const now = new Date();
        const deadlineDate = new Date(data.deadline_date);
        setIsDeadlinePassed(deadlineDate <= now); // Проверка прошла ли дата выполнить до
      }
      if (data?.completed_date && data?.deadline_date) {
        const completedDate = new Date(typeof data?.completed_date === "number" ? data?.completed_date * 1000 : data?.completed_date);
        const deadlineDate = new Date(data.deadline_date);
        setIsLateSubmission(completedDate > deadlineDate); // Проверка срока сдачи на опоздание
      }
    } catch (error) {
      console.error('Ошибка при проверке даты дедлайна:', error);
    }
  }, [data?.completed_date, data?.deadline_date]);

  let sendOrCreatedAt = data?.send_at ? moment(data?.send_at, 'YYYY-MM-DD HH:mm:ss') : moment(data?.created_at, 'YYYY-MM-DD HH:mm:ss');

  if(data.type=='control'){
    sendOrCreatedAt = sendOrCreatedAt.add(3, 'hours');
  }
  console.log(sendOrCreatedAt)

  return <div className={s.leftSideCard}>
    {(data?.send_at || data?.created_at) && (
      <span className={classNames("booble booble--blue", s.actionBooble)}>
        Задано {sendOrCreatedAt.format('D MMMM [в] HH:mm')}
      </span>
    )}
    {data?.deadline_date && (
      <span className={classNames("booble booble--blue", s.actionBooble, { [s.actionBoobleRed]: isDeadlinePassed })}>
        Выполнить до {formatDate(data?.deadline_date)}
      </span>
    )}
    {data?.completed_date && (
      <span
        className={classNames("booble booble--blue", s.actionBooble, { [s.actionBoobleRed]: isLateSubmission, [s.actionBoobleGreen]: !isLateSubmission })}
      >
        Сдано: {formatDate(data?.completed_date)}
      </span>
    )}
    {data?.verified_date && (
      <span
        className={classNames("booble booble--blue", s.actionBooble)}
      >
        Проверено: {formatDate(data?.verified_date)}
      </span>
    )}
    {data.score > 0 && (
      <span className={classNames("booble booble--blue", s.actionBooble)}>
        {data.score} {declOfNum(data.score, ["балл", "балла", "баллов"])}
      </span>
    )}
    {data.type !== "abstract" && (data?.status != 15 || role != "children") && <>
      <span className={classNames("booble booble--blue", s.actionBooble, s.buttonInfoBigPaddings)}>
        {markText}
        {/* {
              true && <span className={classNames("actionBoobleSubtitle")}>в Эл.журе за {formatDateToDate("2023-04-25 00:01:23")}</span>
            } */}
      </span>
    </>}
  </div>;
}

const Btn = ({ link, text }) => (
  <Link to={link} className={s.buttonActionRoundedLink}>
    <Button 
      className={s.buttonActionRounded}
      newDesign
    >
        {text}
    </Button>
  </Link>
);
