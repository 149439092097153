import { checkIsToday, compareDates, formateDate } from '../helpers/date';
import { formateName } from '../helpers/formateName';
import { nextId } from '../helpers/nextId';

export const TimetableService = {
	getAllTimetable: (data, month) => {
		const repair = {};
		const event = {};
		const tasks = [];
		
		data?.forEach((day) => {
			if (day.interval_type === 'regular') {
				if (day.is_cancel !== 1) {
					if (repair[day.repeat_day]) repair[day.repeat_day].push(day.id);
					else repair[day.repeat_day] = [day.id];
				}
				tasks.push(day);
			} else if (
				day.interval_type === 'one-time' &&
				!day.repeat_day &&
				day.is_cancel !== 1
			) {
				tasks.push(day);
			} else if (day.repeat_day && day.is_cancel !== 1) {
				tasks.push(day);
			} else if (day.event_id && day.is_cancel !== 1) {
				if (event[day.event_id]) event[day.event_id].push(day);
				else event[day.event_id] = [day];
			} else tasks.push(day);
		});

		const getTask = (date, weekNumber) => {
			const newTaskList = [];
			tasks.forEach((item) => {
				if (item.interval_type === 'regular' && item.is_biweekly==1
					&& (((Date.parse(date) - Date.parse(item.date_conference))/60/60/24/1000 % 14) !== 0)
					) {
					return;
				}
				if (
					item.interval_type === 'regular'
					&& (
						(item.start_date !== null && new Date(item.start_date) > new Date(date))
						|| (item.end_date !== null && new Date(item.end_date) < new Date(date))
					)
				) {
					return;
				}

				if (
					date === item.date_conference &&
					!item?.reports?.find((el) => el.date_conference === date && el.status > 8)
				) {
					const event = item?.reports?.find(
						(event) => event.date_conference === date
					);

					if (!event || (event && event.status < 6)) {
						newTaskList.push(
							TimetableService._createConference(newTaskList, item)
						);
					}
				}

				if (event[item.id]) {
					event[item.id].forEach((eventItem) => {
						if (date === eventItem.date_conference) {
							newTaskList.push(
								TimetableService._createConference(newTaskList, eventItem)
							);
						}
					});
				}

				if (!newTaskList.find((i) => i.task_id === item.id || i.task_id === item.event_id)) {
					if (repair[weekNumber] && repair[weekNumber].includes(item.id)) {
						if (compareDates(new Date(item.date_conference), new Date(date)) < 0) {
							const event = item.reports?.find(
								(event) => event.date_conference === date
							);

							if (!event || (event && event?.status < 9)) {
								newTaskList.push(
									TimetableService._createConference(newTaskList, item)
								);
							}
						}
					}
				}

				if (!newTaskList.find((i) => i.task_id === item.id || i.task_id === item.event_id)) {
					const event = item.reports?.find(
						(event) => event.date_conference === date
					);
					if (event && event?.status < 9 && item.interval_type !== 'one-time') {
						newTaskList.push(
							TimetableService._createConference(newTaskList, item)
						);
					}
				} else {
					if (date === item.date_conference) {
						const index = newTaskList.findIndex(
							(i) => i.task_id === item.event_id
						);
						if (item.is_cancel) newTaskList.splice(index, 1);
						else
							newTaskList[index] = TimetableService._createConference(
								newTaskList,
								item
							);
					}
				}
			});

			return newTaskList;
		};

		const calendarMonth = month.calendarDays.map(
			({ date, dayShort, dayNumberInWeek }) => {
				const dateFormate = formateDate(date, 'YYYY-MM-DD');
				const weekNumber = ['вс', 'Sun'].includes(dayShort) ? 7 : dayNumberInWeek - 1;
				return {
					// dayShort,
					// weekNumber,
					date: dateFormate,
					tasks: getTask(formateDate(date, 'YYYY-MM-DD'), weekNumber),
				};
			}
		);
		
		return { tasks, calendarMonth };
	},

	_createConference: (list, data) => ({
		id: nextId(list),
		task_id: data.id,
		name:
			data?.group?.name ??
			formateName(data?.children?.name, 'F I') ??
			(data.conference_type === 'meeting' ? 'Совещание' : ''),
		direction:
			data.conference_type === 'meeting'
				? data?.name
				: data?.direction?.name
				? data.direction?.name
				: data?.material?.course?.direction?.name,
		start_time: data?.start_time,
		end_time: data?.end_time,
		conference_type: data.conference_type,
		author_id: data.author_id,
		is_offline: data.is_offline,
		audience_number: data.audience_number,
		is_choose_material: data.is_choose_material,
		material_comment: data.material_comment,
		speaker_id: data.speaker_id,
		unavailableEvents:  data.unavailableEvents,
		notes: data.notes,
		price_via_schedule: data.price_via_schedule,
		available_via_schedule: data.available_via_schedule,
		timetableEnrollments: data.timetableEnrollments,
		deny_access: data?.deny_access,
		seminarianLesson: data?.seminarianLesson,
		additionalTeacher: data?.additional_teacher
	}),

	getAllForthcoming: (data) => {
		const d = new Date();

		//
		let currentTimeNewFormat = `${d.getHours()}:${d.getMinutes()}:00`;
		currentTimeNewFormat = currentTimeNewFormat.split(':')
		
		currentTimeNewFormat.forEach((element, i) => {
			if(element.length==1){
				currentTimeNewFormat[i]=`0${currentTimeNewFormat[i]}`;
			}
		});
		currentTimeNewFormat = currentTimeNewFormat.join(':');
		//
		
		const tasks = data?.calendarMonth
			?.find((item) => checkIsToday(new Date(item.date)))
			?.tasks?.filter(
				(item) => item.end_time > currentTimeNewFormat
			)
			?.sort((a, b) => (a.start_time > b.start_time ? 1 : -1))
			.map((item) => {
				return {
					...item,
					task: data?.tasks?.find(({ id }) => id === item.task_id),
				};
			});
		return tasks;
	},
	getForthcoming: (data, interval = 5) => {
		const d = new Date(new Date().getTime() + interval * 60000);
		const time = `${d.getHours().toString().padStart(2, '0')}:${d
			.getMinutes()
			.toString()
			.padStart(2, '0')}:00`;
		return data?.find((item) => item.start_time < time) ?? null;
	},
};
