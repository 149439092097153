import s from './AuthPlaceholder.module.css'
import img from '../../../../images/atTable.png';
import { useSelector } from 'react-redux';
import Preloader from "@UI/Preloader";
import { checkSettingsStore } from '../../../../services/SettingsStoreUpdateService';
import { useEffect } from 'react';

const AuthPlaceholder = ({isPreview = false, titlePreview, subTitlePreview, imgPreview}) => {
    const titleLoginPage = useSelector(store => store.settings.titleLoginPage);
    const subTitleLoginPage = useSelector(store => store.settings.subTitleLoginPage);
    const imageLoginPage = useSelector(store => store.settings.imageLoginPage);
    const status = useSelector(store => store.settings.status);
    const isLoaded = status == 2;

    useEffect(() => {
        if (!isPreview)
            checkSettingsStore();
    }, []);

  return (
  <div className={s.wrapper} style={ isLoaded? {}: { alignSelf: 'center' } }>
    { 
        isLoaded? (<>
            <h2 className={s.title}>{ isPreview? titlePreview: titleLoginPage }</h2>
            <h3 className={s.subtitle}>{ isPreview? subTitlePreview: subTitleLoginPage }</h3>
            <img className={s.img} src={ (isPreview && imgPreview)? imgPreview: (imageLoginPage? imageLoginPage: img) } alt="" />
        </>) :
        <div className={s.preloader}><Preloader /></div>
    }
  </div>
)}

export default AuthPlaceholder
