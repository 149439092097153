import Preloader from '../../UI/Preloader'
import FrontTaskCard from './FrontTaskCard'
import s from './FrontTaskCardList.module.css'


export const FrontTaskCardList = ({ works, refD, loading, getLinkAllDz=false, ...props }) => {

  const getLink = data => {
    console.log(data)
    
    let type = getLinkAllDz ? data.type : props.type;

    if (props.role !== 'children') {
      switch (type) {
        case 'probe': return `/dz-students/checkingProbe/${data.id}`
        case 'control': return `/dz-students/checkingControl/${data.id}`
        default: return `/dz-students/checking/${data.id}`
      }
    }

    switch (type) {
      case 'probe': return `/home-work/probe-${data.work_id}`
      case 'control': return `/home-work/control-${data.id}`
      default: return false
    }
  }

  const params = {}
  if (refD) params.ref = refD

  return <div {...params} className={s.list}>
    {works?.map(card => <FrontTaskCard key={card.id} data={card} link={getLink(card)} {...props} />)}
    {loading && <Preloader fullScreen='center' />}
  </div>
}
